<template>
  <AddCustomerForm/>
</template>

<script>
import AddCustomerForm from "@/components/customers/AddCustomerForm";

export default {
  components: {
    AddCustomerForm,
  },
};
</script>

