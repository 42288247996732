<template>
  <b-form @submit.prevent="addCustomer">
    <b-form-group label="Prénom" label-for="first-name-input">
      <b-form-input
          id="first-name-input"
          v-model="$v.customer.firstName.$model"
          type="text"
          required
          placeholder="Renseignez un prénom"
          :state="$v.customer.firstName.$dirty ? !$v.customer.firstName.$invalid : null"
      />
      <b-form-invalid-feedback :state="!$v.customer.firstName.$invalid">
        <span v-if="!$v.customer.firstName.required">Vous devez renseigner ce champ</span>
        <span v-else-if="!$v.customer.firstName.alpha">Vous devez renseigner un prénom valide</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Nom" label-for="last-name-input">
      <b-form-input
          id="last-name-input"
          v-model="$v.customer.lastName.$model"
          type="text"
          required
          placeholder="Renseignez un nom"
          :state="$v.customer.lastName.$dirty ? !$v.customer.lastName.$invalid : null"
      />
      <b-form-invalid-feedback :state="!$v.customer.lastName.$invalid">
        <span v-if="!$v.customer.lastName.required">Vous devez renseigner ce champ</span>
        <span v-else-if="!$v.customer.lastName.alpha">Vous devez renseigner un nom valide</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group label="Email" label-for="email-input">
      <b-form-input
          id="email-input"
          v-model="$v.customer.email.$model"
          type="email"
          required
          placeholder="Renseignez une adresse email"
          :state="$v.customer.email.$dirty ? !$v.customer.email.$invalid : null"
      />
      <b-form-invalid-feedback :state="!$v.customer.email.$invalid">
        <span v-if="!$v.customer.email.required">Vous devez renseigner ce champ</span>
        <span v-else-if="!$v.customer.email.email">Vous devez renseigner une addresse email valide</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-button type="submit" variant="primary" :disabled="$v.$invalid">
      Envoyer
    </b-button>
  </b-form>
</template>

<script>
import {email, required} from "vuelidate/lib/validators";
import {name} from "@/validators";

export default {
  data() {
    return {
      customer: {
        firstName: "",
        lastName: "",
        email: "",
      },
    };
  },
  validations: {
    customer: {
      firstName: {
        required,
        name,
      },
      lastName: {
        required,
        name,
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    addCustomer() {
      this.$store
          .dispatch("customers/addCustomer", this.customer)
          .then(async () => {
            await this.$swal.fire({
              icon: "success",
              title: "Client ajouté",
            });

            await this.$router.push({name: "customers"});
          })
          .catch((e) => {
            this.$swal.fire({
              icon: "error",
              title: "Une erreur est survenue lors de la création du client",
              text: e.message,
            });
          });
    },
  },
};
</script>

